import { useState } from 'react'

import brstLogo from '../../assets/images/brst.png'
import brstIcon from '../../assets/images/brst__icon.png'

import './styles.css'

export default function Header() {
  const [width, setWidth] = useState(window.innerWidth)

  window.addEventListener('resize', (e) => setWidth(window.innerWidth))

  return (
    <header>
      <div className="container">
        {width > 500 ? (
          <img src={brstLogo} alt="Brasil Sistemas" />
        ) : (
          <img src={brstIcon} alt="Brasil Sistemas" className="small-image " />
        )}

        <button onClick={() => window.open('https://ruralhub.com.br/areacliente')}>Área do Cliente</button>
      </div>
    </header>
  )
}
