import { useState } from 'react'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import arrowDownBlock from '../../assets/icons/arrow__down__block.png'
import arrowDown from '../../assets/icons/arrow__down.png'
import arrowUp from '../../assets/icons/arrow__up.png'
import whatsappIcon from '../../assets/icons/whatsapp.png'

import safrasIcon from '../../assets/images/systems/safra.png'
import transportesIcon from '../../assets/images/systems/transporte.png'
import corretoraIcon from '../../assets/images/systems/corretora.png'
import financasIcon from '../../assets/images/systems/financas.png'
import siloVirtualIconBranco from '../../assets/images/systems/silo__virtual__icone__branco.png'
import brstIcon from '../../assets/images/brst__icon.png'
import handshake from '../../assets/images/handshake.png'

import bedin from '../../assets/clients/bedin.png'
import multitrato from '../../assets/clients/multitrato.png'
import opcoes from '../../assets/clients/opcoes.png'
import rural from '../../assets/clients/rural.png'

import ruralHub from '../../assets/images/rural.png'

import './styles.css'

export default function Home() {
  const [showArrowUpBadge, setShowArrowUpBadge] = useState(false)

  window.addEventListener('scroll', (e) => {
    if (window.scrollY > 200) {
      setShowArrowUpBadge(true)
    } else if (window.scrollY < 200) {
      setShowArrowUpBadge(false)
    }
  })

  const handleScroll = (section: string) => {
    const HTMLElement = document.getElementById(section)

    if (HTMLElement) {
      window.scrollTo({
        top: HTMLElement.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div>
      <Header />

      <section className="home" id="home">
        <div className="over__green__layer" />

        <div className="container">
          <h1>Sistemas multiplataforma personalizados para o seu negócio.</h1>

          <h5>
            Atuamos em diversos segmentos com diferentes ferramentas, visando garantir eficiência e mais rentabilidade
            aos seus processos.
          </h5>

          <img src={arrowDownBlock} alt="Flecha para baixo" onClick={() => handleScroll('solutions')} />
        </div>
      </section>

      <section className="solutions" id="solutions">
        <div className="container">
          <div className="know__more">
            <h2>Tenha mais rendimento com nossos sistemas</h2>

            <h5>
              A Brasil Sistemas tem seus próprios padrões de qualidade. Sendo assim, todas as aplicações desenvolvidas
              por nossa equipe são projetadas, estruturadas e testadas com base em uma metodologia exclusiva de
              desenvolvimento e rigorosos padrões de qualidade.
            </h5>

            <div className="buttons">
              <a href="https://ruralhub.com.br" target="_blank">
                Conhecer Soluções
              </a>

              <button onClick={() => handleScroll('about__us')}>
                Saiba mais
                <img src={arrowDown} alt="Flecha para baixo" />
              </button>
            </div>
          </div>

          <div className="systems__icons">
            <div className="system" id="br_armazem">
              <img src={siloVirtualIconBranco} alt="BrArmazém" style={{ width: '45px' }} />
              <h4 className="white">Silo Virtual</h4>
            </div>

            <div className="system" id="br_corretora">
              <img src={corretoraIcon} alt="BrCorretora" />
              <h4>
                Br<h4 className="white">Corretora</h4>
              </h4>
            </div>

            <div className="system" id="br_safra">
              <img src={safrasIcon} alt="BrSafra" />
              <h4>
                Br<h4 className="white">Safra</h4>
              </h4>
            </div>

            <div className="system" id="br_financas">
              <img src={financasIcon} alt="BrFinanças" />
              <h4>
                Br<h4 className="white">Finanças</h4>
              </h4>
            </div>

            <div className="system" id="br_transporte">
              <img src={transportesIcon} alt="BrTransporte" />
              <h4>
                Br<h4 className="white">Transporte</h4>
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="about__us" id="about__us">
        <div className="container">
          <div className="title">
            <h5>sobre nós</h5>

            <h1>Simplificamos a inovação utilizando tecnologias inteligentes.</h1>
          </div>

          <div className="summary">
            <p>
              Somos uma empresa com mais de 15 anos de atuação na área de Tecnologia da informação focada no
              agronegócio.
            </p>

            <p>
              Com Softwares personalizados criamos a solução ideal para sua empresa. Sistemas completos e intuitivos,
              desenvolvidos para o controle do chão de fabrica até a alta administração.
            </p>
          </div>
        </div>
      </section>

      <section className="statistics">
        <div className="container">
          <div className="apresentation">
            <h1>Faça parte de mais um caso de sucesso!</h1>

            <div className="details">
              <div className="category">
                <div className="numbers">
                  + <h3>15 anos</h3>
                </div>
                <h5>Atuando com desenvolvimento de softwares.</h5>
              </div>

              <div className="category">
                <div className="numbers">
                  + <h3>1.000</h3>
                </div>
                <h5>Usuários satisfeitos com a nossa inovação.</h5>
              </div>
            </div>
          </div>

          <div className="expansion">
            <img src={brstIcon} alt="Brasil Sistemas" />
          </div>
        </div>
      </section>

      <section className="clients">
        <div className="container">
          <h1>
            Conheça alguns <br /> dos nossos clientes
          </h1>

          <img src={handshake} alt="Compromisso" className="appointment" />

          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img src={rural} alt="" />
              </div>
              <div className="slide">
                <img src={multitrato} alt="" />
              </div>
              <div className="slide">
                <img src={opcoes} alt="" />
              </div>
              <div className="slide">
                <img src={bedin} alt="" />
              </div>
              <div className="slide">
                <img src={rural} alt="" />
              </div>
              <div className="slide">
                <img src={multitrato} alt="" />
              </div>
              <div className="slide">
                <img src={opcoes} alt="" />
              </div>
              <div className="slide">
                <img src={bedin} alt="" />
              </div>
              <div className="slide">
                <img src={rural} alt="" />
              </div>
              <div className="slide">
                <img src={multitrato} alt="" />
              </div>
              <div className="slide">
                <img src={opcoes} alt="" />
              </div>
              <div className="slide">
                <img src={bedin} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="new__identity">
        <div className="container">
          <h1>
            A identidade visual da Brasil Sistemas <br /> está mudando.
          </h1>

          <div className="history">
            <div className="stage">
              <div className="text">
                <h5>história</h5>
                <h2>
                  Brasil Sistemas foi a representatividade de inovação e a cara da nossa empresa por mais de 15 anos.
                </h2>
              </div>

              <img src={brstIcon} alt="Brasil Sistemas" />
            </div>

            <div className="stage">
              <div className="text">
                <h5>inovação</h5>
                <h2>
                  Inovar sempre foi um dos pilares da nossa empresa, com a nossa evolução vimos a necessidade de nos
                  reinventarmos, por isso, estamos nos tornando RuralHub.
                </h2>
              </div>

              <img src={ruralHub} alt="Rural Hub" />
            </div>
          </div>

          <div className="buttons">
            <a href="https://ruralhub.com.br" target="_blank">
              Conheça o RuralHub
            </a>

            <button onClick={() => handleScroll('footer')}>
              Fale conosco
              <img src={arrowDown} alt="Flecha para baixo" />
            </button>
          </div>
        </div>
      </section>

      <Footer />

      {showArrowUpBadge && (
        <div className="scroll__to__top" id="scroll__to__top" onClick={() => handleScroll('home')}>
          <img src={arrowUp} alt="Flecha pra cima" />
        </div>
      )}

      <div
        className="whatsapp__contact"
        id="whatsapp__contact"
        onClick={() =>
          window.open('https://wa.me/556699984060?text=Olá, estou entrando em contato pelo site da Brasil Sistemas.')
        }
      >
        <img src={whatsappIcon} alt="WhatsApp" />
      </div>
    </div>
  )
}
